import React from 'react';
import * as Styled from './slpSVGWithParagraphStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash } from '../../utils';

const SlpSVGWithParagraph = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header1>{children}</Styled.Header1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParagraphStyle>{children}</Styled.ParagraphStyle>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrl(node?.data?.uri)
              ? node?.data?.uri
              :addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.Body>
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </Styled.Body>
  );
};

export default SlpSVGWithParagraph;
