import styled from 'styled-components';

export const ImageStyle = styled.img`
  max-width: 250px;
  width: 300px;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 0;
  vertical-align: top;
`;
export const HeaderH2 = styled.h2`
  font-family: 'Figtree', arial, sans-serif;
  text-transform: none;
  font-size: 36px;
  line-height: 46px;
  color: #0e4c88;
  font-weight: normal !important;
  padding: 0;
  margin:0px !important;
  b {
    color: #00a7e9;
  }
  @media (max-width: 870px) {
    font-size: 26px;
    line-height: 33px;
    b {
      color: #00a7e9;
    }
  }
`;

export const ParagraphStyle = styled.p`
  font-size: 18px;
  line-height: 130%;
  color: #484848;
  padding: 0;
  margin: 0;
  font-family: 'Figtree', arial, sans-serif;
  letter-spacing: 0.02em;
  b {
    color: #00a7e9;
  }
  code {
    font-size: 13px;
    line-height: 15px;
    color: #929497;
    padding: 0;
    font-family: 'Helvetica-Condensed', arial, sans-serif;
  }
  @media (max-width: 870px) {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 2px;
    b {
      color: #00a7e9;
    }
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00447c;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  b {
    color: #00447c;
  }
`;

export const Header1 = styled.h1`
  font-size: 46px;
  line-height: 56px;
  padding: 25px 0 0 0;
  margin: 0 0 25px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539f;
`;

export const Body = styled.div`
  margin: 0 auto !important;
  padding: 0;
  padding-top: 25px !important;
  text-align: center;
  padding-bottom: 30px;
  margin: 0;
  padding: 0 24px 0 7px;
  color: #00539f;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  @media (max-width: 870px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 25px;
  }
`;
